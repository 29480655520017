// To parse this data:
//
//   import { Convert, OWMData } from "./file";
//
//   const oWMData = Convert.toOWMData(json);

export interface OWMData {
  coord:      Coord;
  weather:    Weather[];
  base:       string;
  main:       Main;
  visibility: number;
  wind:       Wind;
  clouds:     Clouds;
  dt:         number;
  sys:        Sys;
  timezone:   number;
  id:         number;
  name:       string;
  cod:        number;
}

export interface Clouds {
  all: number;
}

export interface Coord {
  lon: number;
  lat: number;
}

export interface Main {
  temp:       number;
  feels_like: number;
  temp_min:   number;
  temp_max:   number;
  pressure:   number;
  humidity:   number;
}

export interface Sys {
  type:    number;
  id:      number;
  country: string;
  sunrise: number;
  sunset:  number;
}

export interface Weather {
  id:          number;
  main:        string;
  description: string;
  icon:        string;
}

export interface Wind {
  speed: number;
  deg:   number;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toOWMData(json: string): OWMData {
      return JSON.parse(json);
  }

  public static oWMDataToJson(value: OWMData): string {
      return JSON.stringify(value);
  }
}
